var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xxl col-md-6 mb-2" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Assigned..",
                          options: [
                            { label: "Assigned to", value: "to" },
                            { label: "Assigned by", value: "by" },
                          ],
                          searchable: false,
                        },
                        on: { input: _vm.inputFilter },
                        model: {
                          value: _vm.assigned,
                          callback: function ($$v) {
                            _vm.assigned = $$v
                          },
                          expression: "assigned",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xxl col-md-6 mb-2" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select admin user..",
                          options: _vm.adminUsers,
                        },
                        on: { input: _vm.inputFilter },
                        model: {
                          value: _vm.selectedAdmin,
                          callback: function ($$v) {
                            _vm.selectedAdmin = $$v
                          },
                          expression: "selectedAdmin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xxl col-md-6 mb-2" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select restaurant..",
                          options: _vm.allRestaurants,
                          disabled: !!_vm.restaurantId,
                        },
                        on: { input: _vm.inputFilter },
                        model: {
                          value: _vm.selectedRestaurant,
                          callback: function ($$v) {
                            _vm.selectedRestaurant = $$v
                          },
                          expression: "selectedRestaurant",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xxl col-md-6 mb-2 mb-md-0" },
                    [
                      _c("v-select", {
                        staticClass: "v-select-filter",
                        attrs: {
                          placeholder: "Select task status..",
                          options: _vm.$globalVar.emailLabels,
                          searchable: false,
                          multiple: "",
                        },
                        on: { input: _vm.inputFilter },
                        scopedSlots: _vm._u([
                          {
                            key: "option",
                            fn: function (label) {
                              return [
                                _c(
                                  "CBadge",
                                  {
                                    staticClass: "my-1",
                                    staticStyle: { "font-size": "85%" },
                                    attrs: { color: label.color },
                                  },
                                  [_vm._v(_vm._s(label.label))]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.status,
                          callback: function ($$v) {
                            _vm.status = $$v
                          },
                          expression: "status",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-lg-3 mb-2 mb-md-0" },
                    [
                      _c("CInput", {
                        staticClass: "mb-0",
                        attrs: {
                          type: "date",
                          lazy: false,
                          max: new Date().toISOString().slice(0, 10),
                        },
                        on: { input: _vm.inputFilter },
                        model: {
                          value: _vm.created_at,
                          callback: function ($$v) {
                            _vm.created_at = $$v
                          },
                          expression: "created_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-lg-9 mb-0" },
                    [
                      _c("CInput", {
                        staticClass: "mb-0",
                        attrs: { type: "search", placeholder: "Search.." },
                        on: { input: _vm.searchFilter },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("TaskTable", {
        attrs: { tasks: _vm.tasks, loading: _vm.loading },
        on: { taskChanged: _vm.getAllTask },
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }